<!-- 划转 -->
<template>
  <div id="MytransferFunds">
    <el-row :gutter="15">
      <el-col :span="8" v-for="(item, index) in BaseMarkets" :key="index">
        <el-card class="box-card" shadow="hover">
          <div class="mains" v-if="item.signFlag == 1">主账户</div>
          <div class="items">{{item.accountName}}</div>
          <div class="items">账户资金: {{(item.balance - item.frozenFunds) | formatDigit}}</div>
          <div class="items">冻结资金: {{item.frozenFunds | formatDigit}}</div>
        </el-card>
      </el-col>
    </el-row>
    <el-row id="MytransferFundss">
    <el-row type="flex" justify="center">
        <el-col :span="3">从</el-col>
        <el-col :span="10">
          <el-select v-model="from" placeholder="请选择账户" @change="change" style="width: 100%">
            <el-option v-for="(item,index) in BaseMarkets" :key="index" :value="item.userAccountid" :label="item.accountName"></el-option>
          </el-select>
        </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="3">划转到</el-col>
      <el-col :span="10">
        <el-select v-model="to" placeholder="请选择账户" @change="changes" style="width: 100%">
          <el-option v-for="(item,index) in BaseMarkets" :key="index" :value="item.userAccountid" :label="item.accountName"></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="3">划转金额</el-col>
      <el-col :span="10">
        <el-input v-model="number" type="number" placeholder="划转金额"><el-input>
        </el-input></el-input></el-col>
    </el-row>
      <el-row  type="flex" justify="center">
        <el-col :span="3">选择账户资金</el-col>
        <el-col :span="10">{{ (selectMarket.balance - selectMarket.frozenFunds) | formatDigit}}</el-col>
      </el-row>
      <el-row  type="flex" justify="center">
        <el-col :span="3">选择账户冻结资金</el-col>
        <el-col :span="10">{{ selectMarket.frozenFunds | formatDigit}}</el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-button :disabled="disable" @click="submit()">提交</el-button>
      </el-row>
    </el-row>
  </div>
</template>
<script>
/*global http protocol*/
export default {
  data() {
    return {
      BaseMarkets: [],
      from: null,
      to: null,
      selectMarket: {
        'accountName': '',
        'balance': 0.00,
        'frozenFunds': 0.00,
        'userAccountid': 0,
        'userId': 0
      },
      asd: null,
      number: '',
      disable: false
    }
  },
  filters: {
    formatDigit(e) {
      console.log(e)
      if (e) {
        return Number(e).toFixed(2)
      } else {
        return Number(0).toFixed(2)
      }
    }
  },
  mounted() {
    this.param_findAccountByUserId()
  },
  methods: {
    // 资金划转市场列表
    param_findAccountByUserId() {
      console.log(12)
      http.postFront(protocol.param_findAccountByUserId).then(response => {
        if (response.data) {
          if (response.data && response.data.code == '0') {
            this.BaseMarkets = response.data.value
            if (this.asd != null) {
               for (let i = 0; i < this.BaseMarkets.length; i++) {
                const element = this.BaseMarkets[i]
                if (element.userAccountid === this.asd) {
                  this.selectMarket = element
                }
              }
            }
            // this.BaseMarkets = [
            //   {
            //     "accountName": "三朵云B2B",
            //     "balance": 1231,
            //     "frozenFunds": 21,
            //     "userAccountid": 13212,
            //     "userId": 2
            //   },
            //   {
            //     "accountName": "三朵云B2B",
            //     "balance": 1231,
            //     "frozenFunds": 13,
            //     "userAccountid": 13231,
            //     "userId": 1
            //   },
            //   {
            //     "accountName": "三朵云B2B",
            //     "balance": 1231,
            //     "frozenFunds": 321,
            //     "userAccountid": 13121,
            //     "userId": 5
            //   } 
            // ]
          }
        }
      })
    },
    // 资金来源
    change(selOption) {
      this.asd = selOption
      console.log(selOption, 999)
      this.selectMarket = {
        'accountName': '',
        'balance': 0.00,
        'frozenFunds': 0.00,
        'userAccountid': 0,
        'userId': 0
      }
      for (let i = 0; i < this.BaseMarkets.length; i++) {
        const element = this.BaseMarkets[i]
        if (element.userAccountid === selOption) {
          this.selectMarket = element
        }
      }
    },
    // 资金去向
    changes(selOption) {
      console.log(selOption)
    },
    submit() {
      if (!this.number) {
        this.$EL_MESSAGE('划转资金未填写')
        return
      }
      if (!this.from) {
        console.log(this.from, 'from')
        this.$EL_MESSAGE('请选择划转账户')
        return
      }
      if (!this.to) {
        this.$EL_MESSAGE('请选择划转账户')
        return
      }
      if (Number(this.number) <= 0) {
        this.$EL_MESSAGE('划转资金填写错误')
        return
      }
      if (Number(this.number) > (Number(this.selectMarket.balance) - Number(this.selectMarket.frozenFunds))) {
        this.$EL_MESSAGE('划转资金超额')
        return
      }
      if (this.from === this.to) {
        this.$EL_MESSAGE('两次选择账户相同')
        return
      }
      this.param_transferFundsOwn()
    },
    // 资金划转(多市场间划转)
    param_transferFundsOwn() {
      var that = this
      this.disable = true
      protocol.param_transferFundsOwn.param.amount = this.number
      protocol.param_transferFundsOwn.param.fromUserAccountId = this.from
      protocol.param_transferFundsOwn.param.toUserAccountId = this.to
      http.postFront(protocol.param_transferFundsOwn).then(response => {
        const { code, message, value } = response.data
        this.$EL_MESSAGE(message)
        this.disable = false
        if (code == 0) {
          this.param_findAccountByUserId()
          // this.from = null
          // this.to = null
          // this.selectMarket = {
          //   'accountName': '',
          //   'balance': 0.00,
          //   'frozenFunds': 0.00,
          //   'userAccountid': 0,
          //   'userId': 0
          // }
          console.log(this.asd, 'this.asd')
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
#MytransferFundss{
  margin-top: 20px;
  // margin-left: 50px;
  // margin-right: 50px;
  font-size: 14px;
  .el-col{
    height: 40px;
    margin-bottom: 10px;
    line-height: 40px;
    // text-align: center;

  }
}
.box-card {
  position: relative;
  .mains {
    position: absolute;
    right: 0;
    top: 15px;
    color: #fff;
    padding: 2px;
    background-color: green;
  }
  .items {
    margin-bottom: 15px;
  }
}
</style>
